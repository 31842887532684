import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import * as strings from 'darwin_core/utils/strings';
import { isMatchingZoneId, sortHomesById } from './utils/helpers';
import { MODES } from './utils/consts';
import { getZone, getSetpoint, getMode, getFrostProtectionSetpoint, getPendingErrors, isAutoOff, isCloudSupported, canSettingsBeSent } from './controls-selectors';
import { getPendingData } from './reducers/pending-reducer';
import { getPendingSettingsData } from './reducers/settings-reducer';
import { getNotificationColour, getNotificationRoute } from './utils/notifications';
import { presentDeviceConnectionType, presentPrtBatteryLevel, presentPrtConnectionStrength, presentDeviceType, presentSiConnectionStrength } from './presenters';
/* eslint-disable max-len */

export var getLastEmail = function getLastEmail(state) {
  return state.auth.email;
};
export var getPassword = function getPassword(state) {
  return state.authSensitive.password;
};
export var isAuthenticated = function isAuthenticated(state) {
  return state.auth.isAuthenticated === true;
};
export var hasReceivedHelloMessage = function hasReceivedHelloMessage(state) {
  return state.auth.helloMessageReceived === true;
};
export var hasHelloMessageTimedOut = function hasHelloMessageTimedOut(state) {
  return state.auth.helloMessageTimeout === true;
};
export var isLoggingOut = function isLoggingOut(state) {
  return state.auth.isLoggingOut === true;
};
export var isDarwinAuthenticated = function isDarwinAuthenticated(state) {
  return state.darwinAuth.isAuthenticated === true;
};
export var getCognitoId = function getCognitoId(state) {
  return state.darwinAuth.cognitoId;
};
export var getDarwinRegistering = function getDarwinRegistering(state) {
  return state.darwinAuth.isRegistering;
};
export var getDarwinRegistrationRequired = function getDarwinRegistrationRequired(state) {
  return state.darwinAuth.registrationRequired;
};
export var getDarwinAuthenticationFailed = function getDarwinAuthenticationFailed(state) {
  return state.darwinAuth.authenticationFailed;
};
export var getDarwinRegistrationFailed = function getDarwinRegistrationFailed(state) {
  return state.darwinAuth.registrationFailed;
};
export var getAcceptedMarketing = function getAcceptedMarketing(state) {
  return state.auth.AcceptedMarketing;
};
export var getAcceptedTerms = function getAcceptedTerms(state) {
  return state.auth.AcceptedTerms;
};
export var getTermsAgreedAt = function getTermsAgreedAt(state) {
  return state.auth.TermsAgreedAt;
};
export var getTermsVersion = function getTermsVersion(state) {
  return state.auth.TermsVersion;
};
export var getTermsAndConditionsCompleted = function getTermsAndConditionsCompleted(state) {
  return state.auth.termsAndConditionsCompleted;
};
export var getDeviceGeoStatus = function getDeviceGeoStatus(state) {
  return state.deviceGeoStatus;
};
export var getAwaitingZoneStatus = function getAwaitingZoneStatus(state) {
  return state.awaitingZoneStatus.requestState;
};
export var getCurrentEmail = function getCurrentEmail(state) {
  if (isAuthenticated(state)) {
    return state.auth.email;
  }

  return '';
};
export var getFirstName = function getFirstName(state) {
  var givenName = '';

  if (state.darwinAuth) {
    var darwinAuthKeys = Object.keys(state.darwinAuth);

    if (darwinAuthKeys.includes('firstName')) {
      var firstName = state.darwinAuth.firstName;
      givenName = firstName;
    }
  }

  return givenName;
};
export var getLastName = function getLastName(state) {
  var familyName = '';

  if (state.darwinAuth) {
    var darwinAuthKeys = Object.keys(state.darwinAuth);

    if (darwinAuthKeys.includes('lastName')) {
      var lastName = state.darwinAuth.lastName;
      familyName = lastName;
    }
  }

  return familyName;
};
export var getIsInDemoMode = function getIsInDemoMode(state) {
  var result = false;

  if (state.darwinAuth) {
    var darwinAuthKeys = Object.keys(state.darwinAuth);

    if (darwinAuthKeys.includes('groups')) {
      var groups = state.darwinAuth.groups;

      if (groups.includes('demo')) {
        result = true;
      }
    }
  }

  return result;
};
export var getDeviceRegistrationAttempts = function getDeviceRegistrationAttempts(state) {
  return state.deviceRegistration.registrationAttempts;
};
export var getDeviceRegistrationSerialNumber = function getDeviceRegistrationSerialNumber(state) {
  return state.deviceRegistration.serialNumber;
};
export var getDeviceRegistrationPin = function getDeviceRegistrationPin(state) {
  return state.deviceRegistration.pin;
};
export var getDeviceRegistrationError = function getDeviceRegistrationError(state) {
  return state.deviceRegistration.deviceRegistrationError;
};
export var getDeviceRegistrationErrorTitle = function getDeviceRegistrationErrorTitle(state) {
  return state.deviceRegistration.errorTitle;
};
export var getDeviceRegistrationSuccess = function getDeviceRegistrationSuccess(state) {
  return state.deviceRegistration.deviceRegistrationSucceeded;
};
export var getDeviceRegistrationInProgress = function getDeviceRegistrationInProgress(state) {
  return state.deviceRegistration.deviceRegistrationInProgress;
};
export var getGatewayList = function getGatewayList(state) {
  return state.gatewayManagement.gateways;
};
export var getGatewayListCallStatus = function getGatewayListCallStatus(state) {
  return {
    inProgress: state.gatewayManagement.listAllDevicesInProgress,
    succeeded: state.gatewayManagement.listAllDevicesSucceeded,
    failed: (state.gatewayManagement.listAllDevicesInProgress || state.gatewayManagement.listAllDevicesSucceeded) === false,
    errorMessage: state.gatewayManagement.listAllDevicesErrorMessage
  };
};
export var getDefaultHomeId = function getDefaultHomeId(state) {
  if (state.homeList.homes.length > 0) {
    return state.homeList.homes.sort(sortHomesById)[0].id;
  }

  return null;
}; // look for other devices in homes that we can copy to
// they're eligable to copy if they're the same endpoint

export var getOtherDevicesSameType = function getOtherDevicesSameType(state, zoneId, excludeThisDevice, ownerOnly) {
  var homes = state.homeList.homes;
  var deviceType = zoneId[1];

  if (homes) {
    var devices = homes;

    if (ownerOnly) {
      devices = homes.filter(function (home) {
        return home.role === 'Owner';
      });
    }

    devices = devices.map(function (home) {
      // role: "Invited"
      var zone = home.zones.filter(function (thisZone) {
        if (thisZone.zoneId[1] === deviceType) {
          return true;
        }

        return false;
      });
      return {
        devices: zone,
        homeName: home.friendlyName,
        homeId: home.id
      };
    }).map(function (home) {
      return home.devices.map(function (device) {
        // if name is just heating, add home name as well
        var name = device.friendlyName === 'Heating' || device.friendlyName === 'Hot Water' ? "".concat(device.friendlyName, " (").concat(home.homeName, ")") : device.friendlyName;
        return _objectSpread(_objectSpread({}, device), {}, {
          friendlyName: name,
          homeId: home.homeId,
          settingsLocked: canSettingsBeSent(state, home.homeId, device.zoneId)
        });
      });
    }).flat(1);

    if (excludeThisDevice) {
      var findDevices = devices.findIndex(function (device) {
        return device.zoneId[0] === zoneId[0];
      });
      devices.splice(findDevices, 1);
    }

    return devices;
  }

  return null;
};
export var getHomes = function getHomes(state) {
  var homes = state.homeList.homes;
  var homeMap = homes.map(function (home) {
    return _objectSpread(_objectSpread({}, home), {}, {
      zones: home.zones.map(function (zone) {
        // updates zones with pending requests
        var pendingSetpoint = getPendingData(state.pendingSetpoint, home.id, zone.zoneId);
        var pendingMode = getPendingData(state.pendingMode, home.id, zone.zoneId);
        var pendingHolidayMode = getPendingData(state.pendingHolidayMode, home.id, zone.zoneId);
        var pendingFrostProtectionSetpoint = getPendingSettingsData(state.pendingPrtSettings, zone.zoneId, 'frostProtectionSetpoint');
        return _objectSpread(_objectSpread({}, zone), {}, {
          setpoint: pendingSetpoint ? pendingSetpoint.setpoint : zone.setpoint,
          mode: pendingMode ? pendingMode.mode : zone.mode,
          holidayMode: pendingHolidayMode ? pendingHolidayMode.holidayMode : zone.holidayMode,
          frostProtectionSetpoint: pendingFrostProtectionSetpoint ? parseInt(pendingFrostProtectionSetpoint.value, 10) : zone.frostProtectionSetpoint
        });
      })
    });
  });
  return homeMap.sort(sortHomesById);
};
export var getHomeById = function getHomeById(state, homeId) {
  return state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });
};
export var getHomeByDeviceRegistrationId = function getHomeByDeviceRegistrationId(state, deviceRegistrationId) {
  return state.homeList.homes.find(function (x) {
    return x.deviceRegistrationId === deviceRegistrationId;
  });
};
export var getIsHomeAwaitingZones = function getIsHomeAwaitingZones(state, home) {
  if (home && home.id) {
    var homeRetrieved = state.homeList.homes.find(function (x) {
      return x.id === home.id;
    });
    return homeRetrieved.awaitingZones;
  }

  return true;
};
export var getHomeNames = function getHomeNames(state, homeIdToExclude) {
  var excludeHomeId;

  if (homeIdToExclude) {
    // if set, we'll exclude a provided home ID
    // this is used during initial set-up
    excludeHomeId = homeIdToExclude.toLowerCase().replace(/:/g, '');
  } else if (state.homeList && state.homeList.selectedHome) {
    // if homesToExclude not set, we'll exclude the current home
    // this means a user is allowed to name a current device the same on the rename screens
    excludeHomeId = state.homeList.selectedHome;
  }

  if (state.homeList.homes) {
    return state.homeList.homes.filter(function (x) {
      return x.id !== excludeHomeId;
    }).map(function (x) {
      return x.friendlyName;
    });
  }

  return [];
};
export var getHomeFriendlyName = function getHomeFriendlyName(state, homeId) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home || !home.friendlyName) {
    return homeId;
  }

  return home.friendlyName;
};
export var getHomeDeviceSerialNumber = function getHomeDeviceSerialNumber(state, homeId) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home || !home.serialNumber) {
    return null;
  }

  return home.serialNumber;
};
export var getHomeDeviceRegistrationId = function getHomeDeviceRegistrationId(state, homeId) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return homeId;
  }

  return home.deviceRegistrationId;
};
export var wasFirstHelloEmpty = function wasFirstHelloEmpty(state) {
  var firstHelloWasEmpty = state.homeList.firstHelloWasEmpty;
  return !!firstHelloWasEmpty;
};
export var getCurrentTemperature = function getCurrentTemperature(state, homeId, zoneId) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });
  var zone = home.zones.find(function (x) {
    return isMatchingZoneId(x.zoneId, zoneId);
  });
  return zone ? zone.temperature : null;
};
export var getFlame = function getFlame(state, homeId, zoneId) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });
  var zone = home.zones.find(function (x) {
    return isMatchingZoneId(x.zoneId, zoneId);
  });

  if (getIsInDemoMode(state)) {
    return zone.temperature < getSetpoint(state, homeId, zoneId);
  } // logic override to hide flame if target temp is below room temp [DV-2091]


  var setpoint = getSetpoint(state, homeId, zoneId);

  if (zone.temperature > setpoint) {
    return false;
  }

  return zone ? zone.flame : null;
};
export var isPlusHoursActive = function isPlusHoursActive(state, prtId) {
  var device = state.zone.find(function (x) {
    return x.prtId === prtId;
  });
  return device ? device.mode === 'plusHours' : false;
};
export var getDeviceList = function getDeviceList(state) {
  return {
    devices: state.homeList.smartInterfaces
  };
};
export var previousHome = function previousHome(state) {
  var _state$homeList = state.homeList,
      homes = _state$homeList.homes,
      selectedHome = _state$homeList.selectedHome;
  var indexOfSelected = homes.findIndex(function (x) {
    return x.id === selectedHome;
  });

  if (indexOfSelected === -1) {
    return 'No smart interfaces';
  }

  if (indexOfSelected === 0) {
    indexOfSelected = homes.length;
  }

  indexOfSelected -= 1;
  var previousDeviceSNInArray = homes[indexOfSelected].id;
  return previousDeviceSNInArray;
};
export var nextHome = function nextHome(state) {
  var _state$homeList2 = state.homeList,
      homes = _state$homeList2.homes,
      selectedHome = _state$homeList2.selectedHome;
  var indexOfSelected = homes.findIndex(function (x) {
    return x.id === selectedHome;
  });

  if (indexOfSelected === -1) {
    return 'No smart interfaces';
  }

  indexOfSelected += 1;
  indexOfSelected %= homes.length;
  var nextDeviceSNInArray = homes[indexOfSelected].id;
  return nextDeviceSNInArray;
};
/*
export const showPlusHoursModal = state =>
  state.pendingPrt.showPlusHoursModal === true;
  */

export var getScheduleUpdateState = function getScheduleUpdateState(state) {
  return state.schedule.request ? state.schedule.request.state : 'None';
};
export var getScheduleCopyState = function getScheduleCopyState(state) {
  return state.schedule.copyScheduleRequest ? state.schedule.copyScheduleRequest.copyScheduleState : 'None';
};
export var getScheduleCopyError = function getScheduleCopyError(state) {
  if (!state.schedule.copyScheduleRequest) {
    return '';
  }

  if (!state.schedule.copyScheduleRequest) {
    return '';
  }

  if (state.darwinAuth.groups.includes('demo')) {
    return '';
  }

  if (state.schedule.copyScheduleRequest.copyScheduleState === 'Timed out') {
    return 'The request to update your schedule has timed out. Please try again.';
  }

  if (state.schedule.copyScheduleRequest.copyScheduleState === 'Rejected') {
    return 'The request to update your schedule was rejected. Please try again.';
  }

  return '';
};
export var getScheduleUpdateError = function getScheduleUpdateError(state) {
  if (!state.schedule.request) {
    return '';
  }

  if (state.darwinAuth.groups.includes('demo')) {
    return '';
  }

  if (state.schedule.request.state === 'Timed out') {
    return 'The request to update your schedule has timed out. Please try again.';
  }

  if (state.schedule.request.state === 'Rejected') {
    return 'The request to update your schedule was rejected. Please try again.';
  }

  return '';
};
export var getScheduleSimpleState = function getScheduleSimpleState(state) {
  return state.schedule.simpleScheduleRequest ? state.schedule.simpleScheduleRequest : 'None';
};
export var getScheduleSimpleError = function getScheduleSimpleError(state) {
  if (!state.schedule.simpleScheduleRequest) {
    return '';
  }

  if (!state.schedule.simpleScheduleRequest) {
    return '';
  }

  if (state.darwinAuth.groups.includes('demo')) {
    return '';
  }

  if (state.schedule.simpleScheduleRequest.simpleScheduleState === 'Timed out') {
    return 'The request to update your schedule has timed out. Please try again.';
  }

  if (state.schedule.simpleScheduleRequest.simpleScheduleState === 'Rejected') {
    return 'The request to update your schedule was rejected. Please try again.';
  }

  return '';
};
export var getZones = function getZones(state, homeId) {
  if (!homeId) {
    return [];
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return [];
  }

  return home.zones || [];
};
export var getIfMultiZone = function getIfMultiZone(state, homeId) {
  var zones = getZones(state, homeId);
  return zones.length > 1;
};
export var getChZones = function getChZones(state, homeId) {
  var zones = getZones(state, homeId);
  return zones.filter(function (x) {
    return x.type === 'CH';
  });
};
export var getSWC = function getSWC(state, homeId) {
  var home = getHomeById(state, homeId);

  if (home) {
    if (home.swc) {
      return home.swc;
    }
  }

  return null;
};
export var getZigbeeRepeaters = function getZigbeeRepeaters(state, homeId) {
  var home = getHomeById(state, homeId);

  if (home) {
    if (home.zigbeeRepeaters) {
      return home.zigbeeRepeaters;
    }
  }

  return [];
};
export var getZigbeeRepeaterById = function getZigbeeRepeaterById(state, homeId, deviceId) {
  var zigbeeRepeaters = getZigbeeRepeaters(state, homeId);
  return zigbeeRepeaters.find(function (x) {
    return x.deviceId === deviceId;
  });
};
export var getSiConnectionStatus = function getSiConnectionStatus(state, homeId) {
  var siConnected = false;

  if (!homeId) {
    return siConnected;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return siConnected;
  }

  if (!home.si || !home.si.connected) {
    return siConnected;
  }

  siConnected = home.si.connected;
  return siConnected;
};
export var getStatusRowText = function getStatusRowText(state, homeId, zoneId, isInHolidayMode) {
  var mode = getMode(state, homeId, zoneId);
  var setpoint = getSetpoint(state, homeId, zoneId);
  var zone = getZone(state, homeId, zoneId);
  var isSiConnected = getSiConnectionStatus(state, homeId);

  if (!zone.connected || !isSiConnected) {
    return 'HALO';
  }

  if (mode === MODES.Off || isInHolidayMode || setpoint <= getFrostProtectionSetpoint(state, homeId, zoneId)) {
    return 'Frost Protection';
  }

  return 'Target Temperature';
};
export var getMinorStatusRowText = function getMinorStatusRowText(state, homeId, zoneId, isInHolidayMode, nextEventAt) {
  var mode = getMode(state, homeId, zoneId);
  var setpoint = getSetpoint(state, homeId, zoneId);
  var zone = getZone(state, homeId, zoneId);
  var isSiConnected = getSiConnectionStatus(state, homeId);

  if (!zone.connected || !isSiConnected) {
    return '';
  }

  var autoOff = isAutoOff(state, homeId, zoneId, isInHolidayMode);

  if (!autoOff && (mode === MODES.Off || isInHolidayMode || setpoint <= getFrostProtectionSetpoint(state, homeId, zoneId))) {
    return 'Active';
  }

  if (mode === MODES.Manual) {
    return 'set to';
  }

  return nextEventAt ? "Until ".concat(nextEventAt) : '';
};
export var getFlags = function getFlags(state) {
  return state.darwinAuth.groups;
};
export var getSiRegistrationState = function getSiRegistrationState(state) {
  return state.siRegistration;
};
export var getoptimisedStartEnabled = function getoptimisedStartEnabled(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'optimisedStartEnabled');

  if (pendingState && pendingState.prtSetting === 'optimisedStartEnabled') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.optimisedStartEnabled;
};
export var getoptimisedStopEnabled = function getoptimisedStopEnabled(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'optimisedStopEndabled' // DV-2489 - this incorrect spelling is intentional due to a CT bug
  ); // DV-2489 - this incorrect spelling is intentional due to a CT bug

  if (pendingState && pendingState.prtSetting === 'optimisedStopEndabled') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.optimisedStopEnabled;
};
export var getdelayedStartEnabled = function getdelayedStartEnabled(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'delayedStartEnabled');

  if (pendingState && pendingState.prtSetting === 'delayedStartEnabled') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.delayedStartEnabled;
};
export var getDisplayToleranceDot1 = function getDisplayToleranceDot1(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'displayToleranceDot1');

  if (pendingState && pendingState.prtSetting === 'displayToleranceDot1') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.displayToleranceDot1;
};
export var prtSettingPending = function prtSettingPending(state, zoneId) {
  // lock a user out if any PRT setting is queued
  var requestState = state.pendingPrtSettings;

  if (requestState && requestState.requests) {
    var pendingState = requestState.requests.filter(function (r) {
      return r.zoneDeviceId === zoneId[0];
    });

    if (pendingState !== undefined && pendingState.length !== 0) {
      return true;
    }
  }

  return false;
};
export var getChildLockEnabled = function getChildLockEnabled(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'childLockOn');

  if (pendingState && pendingState.prtSetting === 'childLockOn') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.childLock;
};
export var getIdentifyModeEnabled = function getIdentifyModeEnabled(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'setIdentifyMode');

  if (pendingState && pendingState.prtSetting === 'setIdentifyMode') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.identifyModeActive;
};
export var getWeatherCompensationEnabled = function getWeatherCompensationEnabled(state, homeId) {
  var home = getHomeById(state, homeId);

  if (home !== null && home !== undefined) {
    if ('weatherCompensationEnabled' in home) {
      return home.weatherCompensationEnabled;
    }
  }

  return true;
};
export var getTemperatureOffset = function getTemperatureOffset(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'temperatureOffset');

  if (pendingState && pendingState.prtSetting === 'temperatureOffset') {
    return pendingState.value / 10;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.temperatureOffset / 10;
};
export var getTimeFormat = function getTimeFormat(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'timeFormat');

  if (pendingState && pendingState.prtSetting === 'timeFormat') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);
  return zone.timeFormat === 1;
};
export var getTimeDisplayFormat = function getTimeDisplayFormat(state, homeId, zoneId) {
  var is24HourFormat = getTimeFormat(state, homeId, zoneId);
  return is24HourFormat ? 'HH:mm' : 'h:mma';
};
export var getAllPrtSettings = function getAllPrtSettings(state, homeId, zoneId) {
  var settings = null;
  var optimisedStopEndabled = getoptimisedStopEnabled(state, homeId, zoneId);
  var optimisedStartEnabled = getoptimisedStartEnabled(state, homeId, zoneId);
  var delayedStartEnabled = getdelayedStartEnabled(state, homeId, zoneId);
  var childLockOn = getChildLockEnabled(state, homeId, zoneId);
  var frostProtectionSetpoint = getFrostProtectionSetpoint(state, homeId, zoneId); // we need to convert temperatureOffset from float to whole number as si dont support float.

  var temperatureOffset = getTemperatureOffset(state, homeId, zoneId) * 10;
  var displayToleranceDot1 = getDisplayToleranceDot1(state, homeId, zoneId);
  var timeFormat = getTimeFormat(state, homeId, zoneId);
  settings = {
    optimisedStartEnabled: optimisedStartEnabled,
    optimisedStopEndabled: optimisedStopEndabled,
    delayedStartEnabled: delayedStartEnabled,
    childLockOn: childLockOn,
    frostProtectionSetpoint: frostProtectionSetpoint,
    temperatureOffset: temperatureOffset,
    displayToleranceDot1: displayToleranceDot1,
    timeFormat: timeFormat
  };
  return settings;
};
export var getPrtRequestStatus = function getPrtRequestStatus(state) {
  return state.pendingCustomReducer;
};
export var getZoneNameRequestStatus = function getZoneNameRequestStatus(state) {
  return state.zoneNamingReducer;
};
export var getModelIdentifier = function getModelIdentifier(state, homeId) {
  var modelIdentifier = '';

  if (!homeId) {
    return modelIdentifier;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return modelIdentifier;
  }

  var zones = getZones(state, homeId);

  if (!zones || zones.length <= 0 || !zones[0]) {
    return modelIdentifier;
  }

  modelIdentifier = zones[0].modelIdentifier;
  return modelIdentifier;
};
export var getBoilerType = function getBoilerType(state, homeId) {
  var boilerType = '';

  if (!homeId) {
    return boilerType;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return boilerType;
  }

  if (!home.boiler || !home.boiler.boilerType) {
    return boilerType;
  }

  return home.boiler.boilerType;
};
export var getCapacity = function getCapacity(state, homeId) {
  var capacity = '';

  if (!homeId) {
    return capacity;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return capacity;
  }

  if (!home.boiler || !home.boiler.capacity) {
    return capacity;
  }

  return home.boiler.capacity;
};
export var getBoilerStatus = function getBoilerStatus(state, homeId) {
  var disconnected = {
    isConnected: false,
    isInFault: false
  };

  if (!homeId) {
    return disconnected;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return disconnected;
  }

  if (!home.boiler) {
    return disconnected;
  }

  return {
    isConnected: !!home.boiler.openThermCommunication,
    isInFault: !!(home.boiler.faults && home.boiler.faults.length > 0)
  };
};
export var getSIProperty = function getSIProperty(state, homeId, property, returnActive) {
  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (home) {
    var homeProperty = home.si[property]; // Returns a string for boolean outputs from selector if enabled, saves converting every one each time

    if (returnActive) {
      var boolProperty = Boolean(homeProperty);
      return boolProperty ? 'Active' : 'Inactive';
    }

    return homeProperty || '';
  }

  return '';
};
export var getOpenThermId003 = function getOpenThermId003(state, homeId) {
  var id003 = '';

  if (!homeId) {
    return id003;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return id003;
  }

  if (!home.boiler || !home.boiler.id003) {
    return id003;
  }

  return home.boiler.id003;
};
export var getOpenThermId011 = function getOpenThermId011(state, homeId) {
  var id011 = '';

  if (!homeId) {
    return id011;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return id011;
  }

  if (!home.boiler || !home.boiler.id011 || !Array.isArray(home.boiler.id011)) {
    return id011;
  }

  var allArrayValuesAreNullOrEmpty = home.boiler.id011.every(function (tspItem) {
    return !tspItem || typeof tspItem === 'object';
  });

  if (allArrayValuesAreNullOrEmpty) {
    return id011;
  }

  return home.boiler.id011;
};
export var getOpenThermId125 = function getOpenThermId125(state, homeId) {
  var id125 = '';

  if (!homeId) {
    return id125;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return id125;
  }

  if (!home.boiler || !home.boiler.id125) {
    return id125;
  }

  return home.boiler.id125;
};
export var getOpenThermId127 = function getOpenThermId127(state, homeId) {
  var id127 = '';

  if (!homeId) {
    return id127;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return id127;
  }

  if (!home.boiler || !home.boiler.id127) {
    return id127;
  }

  return home.boiler.id127;
};
export var getSiConnectionStrength = function getSiConnectionStrength(state, homeId) {
  var siConnectionStrength = 'poor';

  if (!homeId) {
    return siConnectionStrength;
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return siConnectionStrength;
  }

  if (!home.si || !home.si.connectionStrength) {
    return siConnectionStrength;
  }

  siConnectionStrength = home.si.connectionStrength;
  return siConnectionStrength;
};
export var getPrtFriendlyName = function getPrtFriendlyName(state, homeId, zoneId) {
  var pendingState = getPendingSettingsData(state.pendingPrtSettings, zoneId, 'zoneName');

  if (pendingState && pendingState.prtSetting === 'zoneName') {
    return pendingState.value;
  }

  var zone = getZone(state, homeId, zoneId);

  if (!zone || !zone.friendlyName) {
    return '';
  }

  return zone.friendlyName;
};
export var getPrtConnectionStatus = function getPrtConnectionStatus(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);

  if (!zone || zone.connectionStatus === undefined) {
    return false;
  }

  return zone.connectionStatus;
};
export var getPrtConnectionStrength = function getPrtConnectionStrength(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);

  if (!zone || !zone.connectionStrength) {
    return 'poor';
  }

  return zone.connectionStrength;
};
export var getPrtBatteryLevel = function getPrtBatteryLevel(state, homeId, zoneId) {
  var zone = getZone(state, homeId, zoneId);

  if (!zone || !zone.batteryLevel) {
    return 'low';
  }

  return zone.batteryLevel;
};
export var getSystemStatus = function getSystemStatus(state, homeId) {
  if (!homeId) {
    return [];
  }

  var systemMap = [];
  var boilerName = getBoilerType(state, homeId);
  var boilerStatus = getBoilerStatus(state, homeId);
  systemMap.push({
    homeId: homeId,
    deviceType: presentDeviceType('boiler'),
    deviceName: boilerName,
    isOffline: !boilerStatus.isConnected,
    isInFault: boilerStatus.isInFault
  });
  var siDeviceName = getHomeFriendlyName(state, homeId);
  var siConnectionStatus = getSiConnectionStatus(state, homeId);
  var siConnectionStrength = getSiConnectionStrength(state, homeId);
  systemMap.push({
    deviceType: presentDeviceType('si'),
    deviceName: siDeviceName,
    connectionType: presentDeviceConnectionType('wifi'),
    connectionStrength: presentSiConnectionStrength(siConnectionStatus, siConnectionStrength),
    isOffline: !siConnectionStatus
  });
  var zones = getChZones(state, homeId);
  var prts = zones.map(function (zone) {
    var prtDeviceName = getPrtFriendlyName(state, homeId, zone.zoneId);
    var prtConnectionStatus = getPrtConnectionStatus(state, homeId, zone.zoneId);
    var prtConnectionStrength = getPrtConnectionStrength(state, homeId, zone.zoneId);
    var prtBatteryLevel = getPrtBatteryLevel(state, homeId, zone.zoneId);
    return {
      deviceType: presentDeviceType('prt'),
      deviceName: prtDeviceName,
      connectionType: presentDeviceConnectionType('zigbee'),
      connectionStrength: presentPrtConnectionStrength(siConnectionStatus, prtConnectionStatus, prtConnectionStrength),
      isOffline: !prtConnectionStatus || !siConnectionStatus,
      batteryLevel: presentPrtBatteryLevel(siConnectionStatus, prtConnectionStatus, prtBatteryLevel),
      homeId: homeId,
      modelIdentifier: zone.modelIdentifier,
      zoneId: zone.zoneId
    };
  });
  var zigbeeRepeaters = getZigbeeRepeaters(state, homeId);
  var parsedZigbeeRepeaters = zigbeeRepeaters.map(function (x) {
    return {
      deviceType: presentDeviceType('zigbeeRepeater'),
      deviceName: x.deviceId,
      isOffline: !x.connectionStatus
    };
  });
  var swc = getSWC(state, homeId);
  var swcArray = [];

  if (swc && swc.deviceId) {
    swcArray = [_objectSpread({
      deviceType: presentDeviceType('swc')
    }, swc)];
  }

  var result = systemMap.concat(prts, parsedZigbeeRepeaters, swcArray);
  return result;
};
export var getFaults = function getFaults(state, homeId) {
  if (!homeId) {
    return [];
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return [];
  }

  if (!home.boiler || !home.boiler.faults) {
    return [];
  }

  if (!Array.isArray(home.boiler.faults)) {
    return [];
  }

  return home.boiler.faults;
};
export var getErrorNotifications = function getErrorNotifications(state) {
  var errors = getPendingErrors(state);
  return errors.map(function (x) {
    return {
      requestId: x.requestId,
      type: 'error',
      homeId: x.homeId,
      raised: x.raised,
      message: x.message
    };
  });
};
export var getNotifications = function getNotifications(state) {
  return state.notifications.notifications.map(function (x) {
    return {
      requestId: x.requestId,
      type: x.type,
      homeId: x.homeId,
      raised: x.raised,
      message: x.title
    };
  });
};
export var getDeviceToken = function getDeviceToken(state) {
  if (!state || !state.notifications) {
    return null;
  }

  if (!state.notifications.deviceToken) {
    return null;
  }

  return state.notifications.deviceToken;
}; // ${platform}_${deviceToken}
// ios_abcd123-asdasjh-z123

export var getPlatformDeviceToken = function getPlatformDeviceToken(state, platform) {
  var deviceToken = getDeviceToken(state);

  if (!deviceToken) {
    return undefined;
  }

  return "".concat(platform, "_").concat(deviceToken);
};
export var getToasts = function getToasts(state) {
  var errorNotifications = getErrorNotifications(state);
  var notifications = getNotifications(state);
  var combinedNotifications = errorNotifications.concat(notifications).map(function (x) {
    return _objectSpread(_objectSpread({}, x), {}, {
      route: getNotificationRoute(x.type),
      colour: getNotificationColour(x.type)
    });
  });
  return combinedNotifications;
};
export var getSystemProblems = function getSystemProblems(state, homeId) {
  if (!homeId) {
    return [];
  }

  var zones = getZones(state, homeId);

  if (!zones || zones.length <= 0) {
    return [];
  }

  var systemMap = getSystemStatus(state, homeId);

  if (!systemMap) {
    return [];
  }

  return systemMap.filter(function (device) {
    return device.isOffline || device.isInFault;
  });
};
export var getControlFaults = function getControlFaults(state, homeId) {
  if (!homeId) {
    return [];
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return [];
  }

  if (!home.controlFaults || !Array.isArray(home.controlFaults)) {
    return [];
  }

  var getFaultCode = function getFaultCode(type) {
    switch (type) {
      // hide fault code for low battery fault
      case 'LowBattery':
        {
          return undefined;
        }

      default:
        {
          return type;
        }
    }
  };

  return home.controlFaults.map(function (x) {
    return _objectSpread(_objectSpread({}, x), {}, {
      faultCode: getFaultCode(x.type),
      gasSafeEngineerRequired: false
    });
  });
};
export var getAllFaults = function getAllFaults(state, homeId) {
  if (!homeId) {
    return [];
  }

  var faults = getFaults(state, homeId);
  var controlFaults = getControlFaults(state, homeId);

  if (!faults || !controlFaults) {
    return [];
  }

  return controlFaults.concat(faults);
};
export var getPrtFaults = function getPrtFaults(state, homeId, zoneId) {
  if (!homeId) {
    return [];
  }

  var controlFaults = getControlFaults(state, homeId);
  var prtControlFaults = controlFaults.filter(function (x) {
    return x.zoneId && isMatchingZoneId(x.zoneId, zoneId);
  });
  return prtControlFaults;
};
export var getSiFaults = function getSiFaults(state, homeId) {
  if (!homeId) {
    return [];
  }

  var controlFaults = getControlFaults(state, homeId);
  controlFaults = controlFaults.filter(function (x) {
    return x.faultCode && x.faultCode.toLowerCase() === 'cf04';
  });
  return controlFaults;
};
export var getSwcFaults = function getSwcFaults(state, homeId) {
  if (!homeId) {
    return [];
  }

  var controlFaults = getControlFaults(state, homeId);
  controlFaults = controlFaults.filter(function (x) {
    return x.faultCode && x.faultCode.toLowerCase() === 'cf02';
  });
  return controlFaults;
};
export var getFaultsBySeverity = function getFaultsBySeverity(state, homeId) {
  if (!homeId) {
    return [];
  }

  var allFaults = getAllFaults(state, homeId);

  if (!allFaults) {
    return [];
  }

  return allFaults.sort(function (faultA, faultB) {
    var faultSeverities = {
      Critical: 0,
      Warning: 1,
      Information: 2
    };
    return faultSeverities[faultA.severity] - faultSeverities[faultB.severity];
  });
};
export var getSystemStatusSeverity = function getSystemStatusSeverity(state, homeId) {
  if (!homeId) {
    return [];
  }

  var faults = getFaultsBySeverity(state, homeId);

  if (faults && faults.length > 0) {
    return faults[0].severity;
  }

  return undefined;
};
export var errorString = function errorString(state, homeId) {
  var cloudDisabled = isCloudSupported(state, homeId);
  if (cloudDisabled) return strings.SETTINGS_DISABLED_BODY;
  return strings.IDEAL_CLOUD_LICENCE_NOT_PRESENT;
};
export var checkForResumeRegistationFlag = function checkForResumeRegistationFlag(state) {
  return state.resumeRegistration.state === 'notStarted';
};
export var showOfflineResumeRegistration = function showOfflineResumeRegistration(state) {
  return state.resumeRegistration.state === 'offline';
};
export var showOnlineResumeRegistration = function showOnlineResumeRegistration(state) {
  return state.resumeRegistration.macAddress && state.resumeRegistration.publicKey && state.resumeRegistration.state === 'online';
};
export var resumeRegistrationDetails = function resumeRegistrationDetails(state) {
  var _state$resumeRegistra = state.resumeRegistration,
      macAddress = _state$resumeRegistra.macAddress,
      publicKey = _state$resumeRegistra.publicKey,
      connectionValidAfter = _state$resumeRegistra.connectionValidAfter;
  return {
    macAddress: macAddress,
    publicKey: publicKey,
    connectionValidAfter: connectionValidAfter
  };
};
export var getUnpairDeviceState = function getUnpairDeviceState(state, homeId, zoneId) {
  // support both prt and zigbee repeaters
  var deviceId = Array.isArray(zoneId) ? zoneId[0] : zoneId;
  var request = state.unpairDevice.requests.find(function (r) {
    return r.homeId === homeId && r.zoneDeviceId === deviceId;
  });

  if (request) {
    return {
      status: request.status,
      requestId: request.requestId
    };
  }

  return {
    status: 'notStarted',
    requestId: undefined
  };
};
export var getPendingInvites = function getPendingInvites(state) {
  var pendingInvites = state.darwinAuth.pendingInvites;

  if (!pendingInvites) {
    return [];
  }

  return pendingInvites;
};
export var invitesArePending = function invitesArePending(state) {
  var pendingInvites = getPendingInvites(state);

  if (!pendingInvites) {
    return false;
  }

  return pendingInvites.length > 0;
};
export var shouldLockToDeviceRegistration = function shouldLockToDeviceRegistration(state) {
  var firstHelloWasEmpty = state.homeList.firstHelloWasEmpty;
  var _state$darwinAuth = state.darwinAuth,
      registrationJustCompleted = _state$darwinAuth.registrationJustCompleted,
      pendingInviteAccepted = _state$darwinAuth.pendingInviteAccepted;
  return firstHelloWasEmpty && registrationJustCompleted && !pendingInviteAccepted;
};
export var getRole = function getRole(state, homeId) {
  if (!homeId) {
    return '';
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return '';
  }

  var role = home.role;
  return role;
};
export var canSettingsBeSentZone = function canSettingsBeSentZone(state, homeId, zoneId) {
  var zoneMode = getZone(state, homeId, zoneId);

  if (zoneMode === MODES.OverrideNext || zoneMode === MODES.OverridePlusHours) {
    return false;
  }

  var mode = getMode(state, homeId, zoneId);

  switch (mode) {
    case MODES.Off:
    case MODES.Manual:
    case MODES.Auto:
      return true;

    default:
      return false;
  }
};
export var canSettingsBeSentToHome = function canSettingsBeSentToHome(state, homeId) {
  var cloudSupported = isCloudSupported(state, homeId);

  if (!cloudSupported) {
    // block options if no cloud license
    return false;
  }

  var zones = getChZones(state, homeId);
  var zoneIds = zones.map(function (x) {
    return x.zoneId;
  });
  var result = true;
  zoneIds.forEach(function (zone) {
    result = result && canSettingsBeSentZone(state, homeId, zone);
  });
  return result;
};
export var settingsDisabled = function settingsDisabled(state, homeId) {
  var role = getRole(state, homeId);

  if (role === 'Owner') {
    return false;
  }

  return true;
};
export var geolocationEnabled = function geolocationEnabled(state, homeId) {
  if (!homeId) {
    return '';
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return '';
  }

  return home.geolocationEnabled;
};
export var getIsDeviceAtHome = function getIsDeviceAtHome(device) {
  return device.currentZone === 0 || device.previousZone === 0 && device.currentZone === 1;
};
export var getIsDeviceAway = function getIsDeviceAway(device) {
  return device.currentZone === 2 || device.previousZone === 2 && device.currentZone === 1;
};
export var getGeolocationDevices = function getGeolocationDevices(state, homeId, deviceId) {
  if (!homeId || !deviceId) {
    return '';
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return '';
  }

  var foundDevice = home.geolocationDevices.find(function (device) {
    return device.id === deviceId;
  });
  return foundDevice;
};
export var geolocationStateThisDevice = function geolocationStateThisDevice(state, homeId, zoneId, deviceId) {
  var foundDevice = getGeolocationDevices(state, homeId, deviceId);
  var isUsing = foundDevice !== undefined;

  if (isUsing) {
    if (getIsDeviceAtHome(foundDevice)) {
      return 'home';
    }

    if (getIsDeviceAway(foundDevice)) {
      return 'away';
    }
  } // if (geolocationEnabled(state, homeId)) {
  //   console.log('Geolocation state unknown, device is', foundDevice);
  // }


  return '';
};
export var geolocationState = function geolocationState(state, homeId, zoneId) {
  if (!homeId) {
    return '';
  }

  var home = state.homeList.homes.find(function (x) {
    return x.id === homeId;
  });

  if (!home) {
    return '';
  }

  var zone = home.zones.find(function (x) {
    return isMatchingZoneId(x.zoneId, zoneId);
  });
  return zone ? zone.geolocationState : null;
};