import _toConsumableArray from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/home/circleci/src/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/*
 * @format
 */
import * as actionTypes from './action-types';
import { sortHomesById } from './utils/helpers'; // [
// { deviceId: "id", selected: true}
// { deviceId: "id2", selected: false}
// ]
// {
//   selectedHome: '08123913724821',
//   si: {
//     boilerControl: '',
//     firmwareVersionSi: '',
//     pairingModeState: 0,
//     bootupReason: '',
//     powerupTime: '',
//     diagnosticsIntervalSeconds: 0,
//     modelName: '',
//     networkSsid: '',
//     wifiRssi: 0,
//     zigbeeChannel: 0,
//     connected: true,
//     inFailsafe: false,
//     userOverridenOn: false,
//     userOverridenOff: false,
//   },
//   boiler: {
//     openThermCommunication: false,
//     faults: [
//       {
//         FaultCode: 'F5',
//         RaisedAt: 1571061539306,
//         Severity: 'Critical',
//         Description: 'Return thermistor short circuit (lockout after 24h) ',
//         ResolutionText:
//           "The boiler in this property is showing a 'return thermistor fault', please contact the tenant and ask them to press the restart button on the front of the boiler.\n\n      If the fault returns after the boiler has been restarted please book an engineer to attend the property, on the call notes please advise the boiler is displaying a 'return thermistor fault'.",
//         GasSafeEngineerRequired:
//           'Once restarted the boiler if the failure continues, a ‘Gas Safe’ engineer is needed',
//       },
//     ],
//   },
//   homes: [
//     {
//       id: '08123913724821',
//       friendlyName: 'Sauce Office',
//       selectedZone: ['prt-123', 0],
//       connectedDevice: true,
//       zones: {
//         zoneId: ['prt-123', 0],
//         type: 'CH',
//         mode: 'auto',
//         temperature: 2150,
//         setpoint: 2300,
//         plusHoursActive: true,
//         flame: false,
//         optimisedStartActive: true,
//         optimisedStopActive: true,
//         delayedStartActive: false,
//         connectionStatus: true,
//         schedule: [
//           {
//             day: 'Sunday',
//             events: [
//               {
//                 startTime: 555,
//                 endTime: 600,
//                 setpoint: 1900,
//               },
//               {
//                 startTime: 600,
//                 endTime: 1230,
//                 setpoint: 2100,
//               },
//             ],
//           },
//         ],
//       },
//     },
//   ],
// };

export var homelistInitialState = {
  selectedHome: '',
  hadFirstHello: false,
  firstHelloWasEmpty: false,
  si: {},
  boiler: {},
  homes: []
};

var parseGeoDevice = function parseGeoDevice(device, deviceRegistrationId) {
  if (device.Id) {
    return {
      id: device.Id,
      deviceRegistrationId: deviceRegistrationId,
      accountId: device.AccountId,
      friendlyName: device.FriendlyName,
      sensitivity: device.Sensitivity,
      currentZone: device.CurrentZone,
      previousZone: device.PreviousZone,
      lastSeenAt: device.LastSeenAt,
      createdAt: device.CreatedAt,
      updatedAt: device.UpdatedAt
    };
  }

  return device;
};

var parseZone = function parseZone(zone) {
  // Central Heating
  if (zone.type === 'CH') {
    return {
      zoneId: zone.zoneId,
      type: zone.type,
      modelIdentifier: zone.modelIdentifier,
      friendlyName: zone.friendlyName,
      mode: zone.temperatureMode,
      temperature: zone.localTemperature,
      setpoint: zone.heatingSetpoint,
      previousSetpoint: zone.previousSetpoint,
      previousTemperatureMode: zone.previousTemperatureMode,
      flame: zone.flame,
      frostProtectionSetpoint: zone.frostProtectionSetpoint,
      schedule: zone.schedule,
      overrideEndtime: zone.overrideEndtime,
      connected: zone.connectionStatus,
      holidayMode: zone.holidayMode,
      holidayModeEnd: zone.holidayModeEnd,
      optimisedStartActive: zone.optimisedStartActive,
      optimisedStopActive: zone.optimisedStopActive,
      delayedStartActive: zone.delayedStartActive,
      optimisedStartEnabled: zone.optimisedStartEnabled,
      optimisedStopEnabled: zone.optimisedStopEnabled,
      delayedStartEnabled: zone.delayedStartEnabled,
      lastMessageRssi: zone.lastMessageRssi,
      connectionStrength: zone.connectionStrength,
      connectionStatus: zone.connectionStatus,
      batteryLevel: zone.batteryLevel,
      temperatureOffset: zone.temperatureOffset,
      firmwareVersion: zone.firmwareVersionPrt,
      childLock: zone.childLock,
      roomThermistor: zone.roomThermistor,
      identifyModeActive: zone.identifyModeActive,
      displayToleranceDot1: zone.displayToleranceDot1,
      timeFormat: zone.timeFormat,
      geolocationState: zone.geolocationState,
      upperLimitLock: zone.upperLimitLock,
      lowerLimitLock: zone.lowerLimitLock
    };
  } // Hot Water


  if (zone.type === 'DHW') {
    return {
      zoneId: zone.zoneId,
      type: zone.type,
      modelIdentifier: zone.modelIdentifier,
      friendlyName: zone.friendlyName,
      flame: zone.flame,
      schedule: zone.schedule,
      mode: zone.temperatureMode,
      holidayMode: zone.holidayMode,
      holidayModeEnd: zone.holidayModeEnd,
      connectionStrength: zone.connectionStrength,
      connectionStatus: zone.connectionStatus,
      connected: zone.connectionStatus,
      batteryLevel: zone.batteryLevel,
      timeFormat: zone.timeFormat,
      overrideEndtime: zone.overrideEndtime,
      previousMode: zone.previousMode
    };
  }

  return '';
};

var parseBoiler = function parseBoiler(boiler) {
  if (boiler) {
    return {
      openThermCommunication: boiler.openThermCommunication,
      boilerType: boiler.boilerType,
      capacity: boiler.capacity,
      id003: boiler.id003,
      id011: boiler.id011,
      id125: boiler.id125,
      id127: boiler.id127,
      faults: boiler.faults
    };
  }

  return '';
};

var parseSi = function parseSi(si) {
  if (si) {
    return {
      connected: si.connected,
      boilerControl: si.boilerControl,
      firmwareVersionSi: si.firmwareVersionSi,
      pairingModeState: si.pairingModeState,
      bootupReason: si.bootupReason,
      powerupTime: si.powerupTime,
      diagnosticsIntervalSeconds: si.diagnosticsIntervalSeconds,
      modelName: si.modelName,
      networkSsid: si.networkSsid,
      wifiRssi: si.wifiRssi,
      connectionStrength: si.connectionStrength,
      zigbeeChannel: si.zigbeeChannel,
      inFailsafe: si.inFailsafe,
      userOverridenOn: si.userOverridenOn,
      userOverridenOff: si.userOverridenOff,
      heatCurveEnabled: si.heatCurveEnabled,
      heatCurveNumber: si.heatCurveNumber
    };
  }

  return '';
};

var updateHomeGeolocationDevices = function updateHomeGeolocationDevices(state, homeId, geolocationDevices) {
  return _objectSpread(_objectSpread({}, state), {}, {
    homes: [].concat(_toConsumableArray(state.homes.filter(function (x) {
      return x.id !== homeId;
    })), [_objectSpread(_objectSpread({}, state.homes.find(function (x) {
      return x.id === homeId;
    })), {}, {
      geolocationDevices: geolocationDevices
    })]).sort(sortHomesById)
  });
};

export var homeList = function homeList() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : homelistInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETED:
      return homelistInitialState;

    case actionTypes.HOME_SELECTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedHome: action.id
      });

    case actionTypes.ZONE_SELECTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        homes: [].concat(_toConsumableArray(state.homes.filter(function (x) {
          return x.id !== state.selectedHome;
        })), [_objectSpread(_objectSpread({}, state.homes.find(function (x) {
          return x.id === state.selectedHome;
        })), {}, {
          selectedZone: action.zoneId
        })])
      });

    case actionTypes.ADD_DEVICE_PLACEHOLDER:
      {
        var homeId = action.homeId,
            deviceRegistrationId = action.deviceRegistrationId,
            friendlyName = action.friendlyName,
            connectedDevice = action.connectedDevice;
        var existing = state.homes.find(function (x) {
          return x.id === homeId;
        });

        if (existing) {
          return state;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          homes: [].concat(_toConsumableArray(state.homes), [{
            id: homeId,
            deviceRegistrationId: deviceRegistrationId,
            serialNumber: homeId,
            friendlyName: friendlyName,
            cloudSupported: connectedDevice,
            boiler: {},
            zones: [],
            controlFaults: []
          }])
        });
      }

    case actionTypes.REMOVE_SI_PLACEHOLDER:
      {
        var _homeId = action.homeId;
        var homeStillInHomes = state.homes.find(function (x) {
          return x.id === _homeId;
        });

        if (homeStillInHomes) {
          var homesWithoutDeregisteredHome = state.homes.filter(function (x) {
            return x.id !== _homeId;
          });
          return _objectSpread(_objectSpread({}, state), {}, {
            homes: homesWithoutDeregisteredHome
          });
        }

        return state;
      }

    case actionTypes.HOMES_MESSAGE_RECEIVED:
    case actionTypes.WEBSOCKET_HELLO_MESSAGE_RECEIVED:
      {
        var data = action.type === actionTypes.HOMES_MESSAGE_RECEIVED ? action.data.data : action.data.homes || action.data.filteredHomes;
        var homes = [];

        if (data && data.length > 0) {
          homes = data.map(function (home) {
            return {
              id: home.id,
              deviceRegistrationId: home.deviceRegistrationId,
              friendlyName: home.friendlyName,
              serialNumber: home.serialNumber,
              latitude: home.latitude,
              longitude: home.longitude,
              selectedZone: home.zones.length > 0 ? home.zones[0].zoneId : undefined,
              boiler: parseBoiler(home.boiler),
              si: parseSi(home.si),
              zigbeeRepeaters: home.zigbeeRepeaters || [],
              swc: home.swc,
              zones: home.zones.map(parseZone),
              cloudSupported: home.connectedDevice,
              controlFaults: home.controlFaults,
              geolocationDevices: home.geolocationDevices.map(function (device) {
                return parseGeoDevice(device, home.deviceRegistrationId);
              }),
              geolocationEnabled: home.geolocationEnabled,
              weatherCompensationEnabled: home.weatherCompensationEnabled,
              homeRadius: home.homeRadius,
              awayRadius: home.awayRadius,
              role: home.role,
              awaitingZones: home.awaitingZones
            };
          });
        }

        var selectedHomeInHomeArray = homes.find(function (home) {
          return state.selectedHome === home.id;
        });
        var newSelectedHome;

        if (selectedHomeInHomeArray) {
          newSelectedHome = selectedHomeInHomeArray.id;
        } else if (homes[0]) {
          newSelectedHome = homes[0].id;
        }

        var firstHelloWasEmpty = state.firstHelloWasEmpty;

        if (!state.hadFirstHello) {
          firstHelloWasEmpty = homes.length === 0;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          hadFirstHello: true,
          firstHelloWasEmpty: firstHelloWasEmpty,
          selectedHome: newSelectedHome,
          homes: homes
        });
      }

    case actionTypes.WEBSOCKET_LIVE_RECEIVED:
      {
        if (!action.data.home || !action.data.home.id) {
          return state;
        }

        var previousHome = state.homes.find(function (h) {
          return h.id === action.data.home.id;
        });

        if (!previousHome) {
          console.error('Home received in live message before hello message', action);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          homes: [].concat(_toConsumableArray(state.homes.filter(function (x) {
            return x.id !== action.data.home.id;
          })), [{
            id: action.data.home.id,
            friendlyName: action.data.home.friendlyName,
            deviceRegistrationId: action.data.home.deviceRegistrationId,
            selectedZone: state.selectedHome ? state.homes.find(function (x) {
              return x.id === state.selectedHome;
            }).selectedZone : undefined,
            boiler: parseBoiler(action.data.home.boiler),
            si: parseSi(action.data.home.si),
            zigbeeRepeaters: action.data.home.zigbeeRepeaters || [],
            swc: action.data.home.swc,
            zones: action.data.home.zones.map(parseZone),
            cloudSupported: action.data.home.connectedDevice,
            controlFaults: action.data.home.controlFaults,
            geolocationDevices: action.data.home.geolocationDevices.map(function (device) {
              return parseGeoDevice(device, action.data.home.deviceRegistrationId);
            }),
            geolocationEnabled: action.data.home.geolocationEnabled,
            weatherCompensationEnabled: action.data.home.weatherCompensationEnabled,
            homeRadius: action.data.home.homeRadius,
            awayRadius: action.data.home.awayRadius,
            latitude: action.data.home.latitude,
            longitude: action.data.home.longitude,
            awaitingZones: action.data.home.awaitingZones,
            // carry over role from hello message
            role: previousHome ? previousHome.role : 'Owner'
          }]).sort(sortHomesById)
        });
      }

    case actionTypes.GEOLOCATION_DEVICE_ADDED:
      {
        var foundHome = state.homes.find(function (home) {
          return home.id === action.data.homeId;
        }); // if already added, don't add again

        if (foundHome.geolocationDevices.find(function (x) {
          return x.geolocationDeviceId === action.data.geolocationDevice.geolocationDeviceId;
        })) {
          return state;
        }

        foundHome.geolocationDevices.push(action.data.geolocationDevice);
        return updateHomeGeolocationDevices(state, action.data.homeId, foundHome.geolocationDevices);
      }

    case actionTypes.GEOLOCATION_DEVICE_REMOVED:
      {
        var _foundHome = state.homes.find(function (home) {
          return home.id === action.data.homeId;
        });

        var filteredGeoDevices = _foundHome.geolocationDevices.filter(function (device) {
          return device.id !== action.data.geolocationDevice.id;
        });

        return updateHomeGeolocationDevices(state, action.data.homeId, filteredGeoDevices);
      }

    case actionTypes.GEOLOCATION_DEVICE_UPDATED:
      {
        var _foundHome2 = state.homes.find(function (home) {
          return home.id === action.data.homeId;
        });

        var deviceIndex = _foundHome2.geolocationDevices.findIndex(function (device) {
          return device.id === action.data.geolocationDevice.id;
        });

        _foundHome2.geolocationDevices[deviceIndex].friendlyName = action.data.geolocationDevice.friendlyName;
        return updateHomeGeolocationDevices(state, action.data.homeId, _foundHome2.geolocationDevices);
      }

    default:
      return state;
  }
};
export var prtInitialState = []; // [
//   {
//     deviceId: 'prt-123',
//     mode: 'auto',
//     temperature: 2150,
//     setpoint: 2300,
//     plusHoursActive: true,
//     flame: false
//   }
// ]

export var zone = function zone() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : prtInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETED:
      return prtInitialState;
    //    case actionTypes.WEBSOCKET_HELLO_MESSAGE_RECEIVED:
    //      return [
    //        ...action.data.zones.map(zoneObj => ({
    //          prtId: zoneObj.serialNumber,
    //          mode: zoneObj.temperatureMode,
    //          temperature: zoneObj.localTemperature,
    //          setpoint: zoneObj.heatingSetpoint,
    //          flame: zoneObj.flame,
    //        })),
    //      ];

    case actionTypes.WEBSOCKET_PRT_LIVE_RECEIVED:
      return [].concat(_toConsumableArray(state.filter(function (x) {
        return x.prtId !== action.data.serialNumber;
      })), [{
        prtId: action.data.serialNumber,
        mode: action.data.temperatureMode,
        temperature: action.data.localTemperature,
        setpoint: action.data.heatingSetpoint,
        flame: action.data.flame
      }]);

    default:
      return state;
  }
};